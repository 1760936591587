import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { ref, computed } from 'vue';

export type ScreenSize = 's' | 'm' | 'l';

export const usePageLayoutStore = defineStore('pageLayout', () => {
  const screenSize = ref<ScreenSize>('l');
  const showMobileMenu = ref(false);
  const navbarWidth = ref(0);
  const headerHeight = ref(0);
  const isModalOpen = ref(false);
  const _isNavExpanded = useStorage('nav_expanded', false);

  const setScreenSize = (width?: number) => {
    const windowWidth = width || document.documentElement.clientWidth;
    screenSize.value = windowWidth < 576 ? 's' : windowWidth > 1024 ? 'l' : 'm';
  };

  const isNavExpanded = computed({
    get() {
      return _isNavExpanded.value && screenSize.value !== 's';
    },
    set(value) {
      _isNavExpanded.value = value;
    }
  });

  return {
    screenSize,
    showMobileMenu,
    navbarWidth,
    headerHeight,
    isModalOpen,
    isNavExpanded,
    setScreenSize
  };
});
