<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's'
      }
    ]"
  >
    <div :class="$style.inner">
      <div :class="$style.toggle">
        <BaseSwitch
          v-model="currentContext"
          :options="[
            {
              label: $t('header.location_select.toggle_global'),
              value: 'GLOBAL'
            },
            {
              label: $t('header.location_select.toggle_local'),
              value: 'LOCAL'
            }
          ]"
          :small="$screen !== 's'"
          :disabled="toggleDisabled"
          :tooltip="
            toggleDisabled
              ? {
                  position: 'bottom',
                  text: $t('header.location_select.toggle_unavailable'),
                  touch: true
                }
              : null
          "
          @update:modelValue="onContextSelect"
          v-test="'location-toggle'"
        />
      </div>
      <div v-if="locationId" :class="$style.select" v-test="'location-select'">
        <BaseDropdown
          v-model="locationId"
          :options="
            locations.map((location) => ({
              value: location.id,
              label: location.internalName
            }))
          "
          :small="$screen !== 's'"
        />
      </div>
    </div>
    <BaseButton
      v-if="$screen === 's'"
      fullWidth
      mt
      @click="
        () => {
          switchLocation(localLocationId);
          $emit('select');
        }
      "
      v-test="'location-submit'"
    >
      {{ $t('global.actions.select') }}
    </BaseButton>
  </div>
</template>

<script lang="ts">
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';

const localRoutes = [
  'admin-absences',
  'admin-employees',
  'admin-rooms',
  'admin-equipment',
  'admin-roster',
  'cashups',
  'cashup',
  'dashboard',
  'drawer',
  'drawer-transaction',
  'drawer-close',
  'products',
  'products-stock-orders',
  'register-builder',
  'register-payment'
];

const toggleRoutes = [
  'admin-export',
  'agenda',
  'calendar',
  'day-overview',
  'day-overview-item',
  'feedback',
  'feedback-item',
  'feedbacks',
  'feedback-publish',
  'invoices',
  'reports'
];

import { defineComponent } from 'vue';
import { useStorage } from '@vueuse/core';

export default defineComponent({
  emits: ['select'],
  setup() {
    return {
      chosenContext: useStorage('location_context', '')
    };
  },
  data() {
    const locationsStore = useLocationsStore();
    const { dataScope, locationId: localLocationId } = useLocationsStore();

    return {
      currentContext: dataScope,
      defaultContext: dataScope,
      toggleDisabled: false,
      locationsStore,
      localLocationId
    };
  },
  watch: {
    isGlobalRoute() {
      this.updateContext();
    },
    isLocalRoute() {
      this.updateContext();
    },
    currentContext: {
      handler(value) {
        if (this.$route.params.locationId) {
          // Only update the scope when the route has a location ID.
          // This is mainly to prevent updating the scope when the user logs out and navigates to the "logged out" page, which would trigger watchers in the calendar.
          this.locationsStore.dataScope = value;
        }
      },
      immediate: true
    },
    locationId() {
      if (this.isToggleRoute) {
        this.currentContext = 'LOCAL';
      }
    }
  },
  computed: {
    ...mapState(useLocationsStore, ['locations']),
    routeName() {
      return this.$route.name;
    },
    isLocalRoute() {
      return !!this.$route?.matched.find((route) =>
        localRoutes.includes(route.name)
      );
    },
    isToggleRoute() {
      return !!this.$route?.matched.find((route) =>
        toggleRoutes.includes(route.name)
      );
    },
    isGlobalRoute() {
      return !this.isLocalRoute && !this.isToggleRoute;
    },
    locationId: {
      get() {
        if (this.$screen === 's') {
          return this.localLocationId;
        } else {
          const { locationId } = useLocationsStore();
          return locationId;
        }
      },
      set(value) {
        if (this.$screen === 's') {
          this.localLocationId = value;
        } else {
          this.switchLocation(value);
        }
      }
    }
  },
  methods: {
    onContextSelect(value) {
      this.chosenContext = value;
    },
    updateContext() {
      if (this.isGlobalRoute) {
        this.currentContext = 'GLOBAL';
        this.toggleDisabled = true;
      } else if (this.isLocalRoute) {
        this.currentContext = 'LOCAL';
        this.toggleDisabled = true;
      } else {
        this.currentContext = this.chosenContext || this.defaultContext;
        this.toggleDisabled = false;
      }
    },
    switchLocation(locationId) {
      this.$router.replace({
        name: this.$route.name,
        params: {
          locationId
        }
      });
    }
  },
  created() {
    this.updateContext();
  }
});
</script>

<style lang="scss" module>
.inner {
  display: flex;
  align-items: center;
  gap: $spacing * 0.5;

  .base.smallScreen & {
    flex-direction: row-reverse;
  }
}
.select {
  .base:not(.smallScreen) & {
    width: 150px;
  }
  .base.smallScreen & {
    width: 100%;
    min-width: 0;
  }
}
</style>
